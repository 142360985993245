<template>
  <base-layout class="pdf-view">
    <base-pdf url="/file/Zq8HwSxQx.pdf" />
  </base-layout>
</template>

<script>
import BasePdf from "@/components/BasePdf";

export default {
  components: {
    BasePdf
  },

  created() {
    document.title = "佣金规则";
  },
  methods: {}
};
</script>

<style lang="less" scoped></style>
